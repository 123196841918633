import React from "react"
import { StaticImage } from "gatsby-plugin-image"

export default function CoreTech() {
  return (
    <section className="core-tech bg-brand-lightgray px-4 pt-24 pb-36 relative">
      <div className="core-tech__triangle w-24 h-24 absolute -top-24 left-1/2 transform -translate-x-1/2 bg-brand-lightgray"></div>
      <h3 className="mb-12 text-brand-blue text-center">
        Core Technical Capabilities
      </h3>
      <div className="container mx-auto md:grid md:grid-cols-4">
        <div className="core-tech__item text-center mb-12 md:mb-0">
          <StaticImage
            className="inline-block mb-6"
            src="../images/icon-ASP-inspect.png"
            alt="Inspect"
            placeholder="blurred"
          />
          <p className="w-3/4 mx-auto">Conventional & Advanced NDT Solutions</p>
        </div>

        <div className="core-tech__item text-center mb-12 md:mb-0">
          <StaticImage
            className="inline-block mb-6"
            src="../images/icon-ASP-maintain.png"
            alt="Maintain"
            placeholder="blurred"
          />
          <p className="w-3/4 mx-auto">Asset Lifecycle Extension Programmes</p>
        </div>

        <div className="core-tech__item text-center mb-12 md:mb-0">
          <StaticImage
            className="inline-block mb-6"
            src="../images/icon-ASP-paint.png"
            alt="Paint"
            placeholder="blurred"
          />
          <p className="w-3/4 mx-auto">
            Surface Preparation &amp; Coating Applications
          </p>
        </div>

        <div className="core-tech__item text-center">
          <StaticImage
            className="inline-block mb-6"
            src="../images/icon-ASP-integrity.png"
            alt="Integrity"
            placeholder="blurred"
          />
          <p className="w-3/4 mx-auto">
            Monitoring of Assets to Determine Compliance to Serviceable
            Standards
          </p>
        </div>
      </div>

      <StaticImage
        className="core-tech-footer-img absolute bottom-0 right-6 sm:right-16"
        src="../images/core-tech-footer-img.png"
        placeholder="blurred"
      />
    </section>
  )
}
