import React from "react"
import CoreTech from "./CoreTech"
import Footer from "./Footer"
import Header from "./Header"
import SHEQWhy from "./SHEQWhy"
import WhatWeDo from "./WhatWeDo"

export default function LayoutAlt({ children }) {
  return (
    <div className="site-wrapper font-brand text-brand-darkgray">
      <Header />
      {children}
      <CoreTech />
      <SHEQWhy />
      <WhatWeDo />
      <Footer />
    </div>
  )
}
