import { Link } from "gatsby"
import React from "react"
import { StaticImage } from "gatsby-plugin-image"

export default function SHEQWhy() {
  return (
    <section className="sheq-why bg-brand-lightgray md:flex md:flex-row">
      <div className="sheq-intro md:w-1/2">
        <div className="sheq-intro__content px-4 md:px-16 py-24">
          <h2 className="text-brand-blue mb-12">SHEQ</h2>
          <p className="mb-6">
            Our Certified Integrated Management System containing ISO 9001, ISO
            45001 and IRATA Codes of Practice encompassing Safety, Health,
            Environment and Quality Objectives, Targets, Accountibility,
            Responsibilities and Continuous Improvement.
          </p>
          <Link
            to="/sheq"
            className="font-semibold text-brand-orange uppercase tracking-widest inline-block mx-auto hover:underline"
          >
            Learn More
          </Link>
        </div>
      </div>

      <div className="why-intro relative md:w-1/2 bg-brand-blue">
        <StaticImage
          className="why-intro__image absolute top-0 right-0 bottom-0 left-0 z-0 h-full w-full object-cover"
          src="../images/why-intro-bg.jpg"
          alt="Rope Access"
          placeholder="blurred"
        />
        <div className="why-intro__content relative z-20 px-4 md:px-16 py-24">
          <h2 className="text-white mb-12">Why Work With Us?</h2>
          <ul className="text-brand-orange list-disc pl-6 mb-6">
            <li>
              <span className="text-white">
                Living our MISSION, VISION AND VALUES
              </span>
            </li>
            <li>
              <span className="text-white">Technical Innovation</span>
            </li>
            <li>
              <span className="text-white">Work Ethic</span>
            </li>
            <li>
              <span className="text-white">Commercial Advantage</span>
            </li>
            <li>
              <span className="text-white">Diversity in Skills</span>
            </li>
          </ul>
          <Link
            to="/about-us#why-asp"
            className="font-semibold text-white uppercase tracking-widest inline-block mx-auto hover:underline"
          >
            Learn More
          </Link>
        </div>
      </div>
    </section>
  )
}
