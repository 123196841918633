import { Link } from "gatsby"
import React from "react"
import { StaticImage } from "gatsby-plugin-image"

export default function WhatWeDo() {
  return (
    <section className="what-we-do px-4 py-24">
      <h2 className="relative text-brand-blue text-center pb-3 mb-3">
        What We Do
        <span className="border-b-2 border-brand-orange inline-block h-1 w-16 absolute bottom-0 left-1/2 transform -translate-x-1/2"></span>
      </h2>

      <h3 className="text-gray-400 text-center mb-24">
        Asset Lifecycle Services
      </h3>

      <div className="sm:px-16 sm:grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mb-16">
        <div className="service-item text-center bg-service-early_engagement p-4 shadow-lg relative pb-16">
          <StaticImage
            className="mb-4 -mt-16"
            src="../images/icon-early-engagement.png"
            alt="Early Engagement"
            placeholder="blurred"
          />
          <h5 className="text-white text-left uppercase font-semibold mb-4 tracking-widest">
            1 - Early Engagement
          </h5>
          <p className="text-white text-left">
            Creating Value to the Customer by engaging early thereby lowering
            costs and streamlining services.
          </p>
        </div>
        <div className="service-item text-center bg-service-concept px-4 pt-4 shadow-lg relative pb-16">
          <StaticImage
            className="mb-4 -mt-16"
            src="../images/icon-concept.png"
            alt="Concept"
            placeholder="blurred"
          />
          <h5 className="text-left text-white uppercase font-semibold mb-4 tracking-widest">
            2 - Concept
          </h5>
          <p className="text-left text-white">
            Involvement at concept enables optimisation at later stages of the
            project.
          </p>
        </div>

        <div className="service-item text-center bg-service-design px-4 pt-4 shadow-lg relative pb-16">
          <StaticImage
            className="mb-4 -mt-16"
            src="../images/icon-design.png"
            alt="Design"
            placeholder="blurred"
          />
          <h5 className="text-left text-white uppercase font-semibold mb-4 tracking-widest">
            3 - Design
          </h5>
          <p className="text-left text-white">
            Comprehensive design ensures minimal alteration and accurate project
            forecasting.
          </p>
        </div>

        <div className="service-item text-center bg-service-install px-4 pt-4 shadow-lg relative pb-16">
          <StaticImage
            className="mb-4 -mt-16"
            src="../images/icon-install.png"
            alt="Install"
            placeholder="blurred"
          />
          <h5 className="text-left text-white uppercase font-semibold mb-4 tracking-widest">
            4 - Install
          </h5>
          <p className="text-left text-white">
            Safe, timeous and cost effective installations by world-leading
            technicians.
          </p>
        </div>

        <div className="service-item text-center bg-service-maintain px-4 pt-4 shadow-lg relative pb-16">
          <StaticImage
            className="mb-4 -mt-16"
            src="../images/icon-maintain.png"
            alt="Maintain"
            placeholder="blurred"
          />
          <h5 className="text-left text-white uppercase font-semibold mb-4 tracking-widest">
            5 - Maintain
          </h5>
          <p className="text-left text-white">
            Tacit and reactive maintenance reducing ownership costs.
          </p>
        </div>

        <div className="service-item text-center bg-service-inspect px-4 pt-4 shadow-lg relative pb-16">
          <StaticImage
            className="mb-4 -mt-16"
            src="../images/icon-inspect.png"
            alt="Inspect"
            placeholder="blurred"
          />
          <h5 className="text-left text-white uppercase font-semibold mb-4 tracking-widest">
            6 - Inspect
          </h5>
          <p className="text-left text-white">
            Regular and detailed inspections mitigating costly repairs.
          </p>
        </div>

        <div className="service-item text-center bg-service-extend px-4 pt-4 shadow-lg relative pb-16">
          <StaticImage
            className="mb-4 -mt-16"
            src="../images/icon-extend.png"
            alt="Extend"
            placeholder="blurred"
          />
          <h5 className="text-left text-white uppercase font-semibold mb-4 tracking-widest">
            7 - Extend
          </h5>
          <p className="text-left text-white">
            Creating Value to the Customer by engaging early thereby lowering
            costs and streamlining services.
          </p>
        </div>

        <div className="service-item text-center bg-service-decommission px-4 pt-4 shadow-lg relative pb-16">
          <StaticImage
            className="mb-4 -mt-16"
            src="../images/icon-decommission-v2.png"
            alt="Decommission"
            placeholder="blurred"
          />
          <h5 className="text-left text-white uppercase font-semibold mb-4 tracking-widest">
            8 - Decommission
          </h5>
          <p className="text-left text-white">
            Facilitate abandonment & decommisioning.
          </p>
        </div>
      </div>

      <div className="text-center">
        <Link className="btn inline-block" to="/contact-us">
          Contact Us
        </Link>
      </div>
    </section>
  )
}
